/* eslint-disable */
if (typeof window === "undefined") {
  global.window = {}
}
const globalDocument = // make ssr happy
  typeof document !== "undefined"
    ? document
    : {
        createElement: () => ({
          addEventListener: () => 0
        }),
        body: { appendChild: () => 0 },
      }
export default new Promise((resolve, reject) => {
  let resolved = false
  let tag = globalDocument.createElement("script")
  tag.src = "https://apis.google.com/js/api.js"
  tag.addEventListener("load", function () {
    if (!resolved) {
      resolve(window.gapi)
      resolved = true
    }
  })
  tag.addEventListener("error", function (result) {
    if (!resolved) {
      reject(result)
      resolved = true
    }
  })
  globalDocument.body.appendChild(tag)
})
