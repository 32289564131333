// Gatsby supports TypeScript natively!
import { Fab } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import { PageProps } from "gatsby"
import React from "react"
import DrivePicker from "../components/DrivePicker"
import TimeNotebook from "../components/TimeNotebook"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Test = (props: PageProps) => (
  <Layout>
    <SEO title="Privacy Policy" />
    <h1>Testing. 1, 2, 3.</h1>
    <p>Just a test page! :)</p>
    {/* <hr />
    <TimeNotebook /> */}
    <hr />
    <DrivePicker></DrivePicker>
    <Fab
      color="primary"
      aria-label="add"
      style={{ position: "fixed", right: "4em", bottom: "4em" }}
      onClick={() => alert("this is a test of the button!")}
    >
      <Add />
    </Fab>
  </Layout>
)

export default Test
