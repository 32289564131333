/// <reference path="../../../node_modules/@types/gapi/index.d.ts" />
/// <reference path="../../../node_modules/@types/gapi.auth2/index.d.ts" />
/// <reference path="../../../node_modules/@types/gapi.client.drive/index.d.ts" />
/// <reference path="../../../node_modules/@types/gapi.drive/index.d.ts" />

// this is so that the types are still active from above.  Otherwise, would do import normally.
import gapiPromise from "./gapi"
/* eslint-disable no-native-reassign */
/* globals google, gapi */
window.gapi = {}

gapiPromise.then(
  g => (window.gapi = g),
  e => console.log(e)
)

const appId =
  "301712555268-h9mkd0j9c9e36oe6sdu299aclqfth1s6.apps.googleusercontent.com"
const apiKey = "AIzaSyCcTw7aP9hPoqgQGT5IpCLiKZyykpzylK0"

export default class Drive {
  constructor(callback) {
    gapiPromise.then(() =>
      gapi.load(
        "client:auth2:picker",
        function () {
          gapi.client
            .init({
              apiKey: apiKey,
              clientId: appId,
              discoveryDocs: [
                "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
              ],
              scope:
                "https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive",
            })
            .then(
              function () {
                console.log(
                  "initialized ",
                  gapi.auth2.getAuthInstance().isSignedIn.get()
                )
                var signinStateHandler = function (isLoggedIn) {
                  console.log("login state change: " + isLoggedIn)
                  if (isLoggedIn) {
                    callback()
                  }
                }
                gapi.auth2
                  .getAuthInstance()
                  .isSignedIn.listen(signinStateHandler)
                if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                  signinStateHandler(
                    gapi.auth2.getAuthInstance().isSignedIn.get()
                  )
                } else {
                  console.log("signing in")
                  gapi.auth2.getAuthInstance().signIn()
                }
              },
              function (error) {
                console.log("error", error)
              }
            )
        },
        e => console.log(e)
      )
    )
  }
  getGapi() {
    return gapi
  }
  listFiles() {
    return gapi.client.drive.files.list({
      pageSize: 10,
      fields: "nextPageToken, files(id, name)",
    })
  }
  pickFile() {
    // Create and render a Picker object for searching images.
    function createPicker() {
      var view = new google.picker.View(google.picker.ViewId.DOCS)
      view.setMimeTypes("image/png,image/jpeg,image/jpg")
      var picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(appId)
        .setOAuthToken(gapi.auth.getToken())
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(apiKey)
        .setCallback(pickerCallback)
        .build()
      picker.setVisible(true)
    }

    // A simple callback implementation.
    function pickerCallback(data) {
      if (data.action === google.picker.Action.PICKED) {
        var fileId = data.docs[0].id
        alert("The user selected: " + fileId)
      }
    }
    createPicker()
  }
  getFile(id) {
    return new Promise((accept, reject) => {
      gapi.client.drive.files
        .get({
          fileId: id,
          alt: "media",
        })
        .then(response => accept(response.body))
        .catch(reason => reject(reason))
    })
  }
  load(name) {
    console.log("HELLO " + name)
  }
}
