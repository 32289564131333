import React from "react"
import driveHelper from "./library/drive"
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Paper,
  Box,
} from "@material-ui/core"
import { InsertDriveFile } from "@material-ui/icons"
import useStickyState from "../components/hooks/use-sticky-state"

var driveIsLoaded = false
const driveLoaded = new Promise(resolve => {
  const driveInstance = new driveHelper(() => {
    driveIsLoaded = true
    resolve(driveInstance)
  })
})

export default function DrivePicker(props) {
  const defaults = {
    items: [],
    currentFile: {},
    content: "",
    initialized: false,
    email: "",
    choosingFile: false,
    loading: false,
  }
  const [persistent, setPersistent] = useStickyState(
    defaults,
    props.persistenceKey || "drivePicker2"
  )
  var [state, setStateReal] = React.useState(persistent)
  const setState = function (...args) {
    console.log("set state to ", ...args)
    setStateReal(...args)
  }
  if (JSON.stringify(state) !== JSON.stringify(persistent)) setPersistent(state)

  if (!driveIsLoaded) {
    if (!state.loading) {
      state = {
        ...state,
        loading: true,
      }
      setState(state)
    }
    console.log("loading drive")
    driveLoaded.then(drive => {
      console.log("got drive")
      state = {
        ...state,
        initialized: true,
        email: drive
          .getGapi()
          .auth2.getAuthInstance()
          .currentUser.get()
          .getBasicProfile()
          .getEmail(),
        loading: "name" in state.currentFile,
      }
      setState(state)
      if (state.loading) {
        console.log("loading file")
        showFile(state.currentFile)()
      }
    })
  }

  function loadItems() {
    state = {
      ...state,
      currentFile: {},
      content: "",
      choosingFile: true,
      loading: true,
    }
    setState(state)
    driveLoaded.then(drive => {
      drive.listFiles().then(response => {
        console.log(response)
        state = {
          ...state,
          items: response.result.files,
          loading: false,
        }
        setState(state)
      })
    })
  }
  function pick() {
    driveLoaded.then(drive => drive.pickFile())
  }
  function showFile(item) {
    return () => {
      state = {
        ...state,
        loading: true,
      }
      setState(state)
      console.log("showing file")
      driveLoaded.then(drive =>
        drive.getFile(item.id).then(function (response) {
          console.log(response)
          state = {
            ...state,
            currentFile: item,
            content: response,
            choosingFile: false,
            loading: false,
          }
          setState(state)
        })
      )
    }
  }

  return (
    <>
      <Paper elevation={3}>
        {state.loading && <LinearProgress />}
        &nbsp;
        <Box m={2}>
          {(state.content || state.choosingFile) && (
            <div>Acting as: {state.email}</div>
          )}
          {state.content && !state.loading && (
            <>
              <p>Name: {state.currentFile.name}</p>
              <pre>{state.content}</pre>
            </>
          )}
          {!state.choosingFile && !state.loading && (
            <>
              <Button
                onClick={loadItems}
                disabled={!driveIsLoaded}
                color="primary"
                variant="contained"
              >
                Choose File
              </Button>
              <Button
                onClick={pick}
                disabled={!driveIsLoaded}
                color="secondary"
                variant="outlined"
              >
                Pick file
              </Button>
            </>
          )}
          {state.choosingFile && (
            <>
              <h3>Items</h3>
              <p>Count: {state.items.length}</p>
              <List component="nav" aria-label="main mailbox folders">
                {state.items.map(x => (
                  <ListItem button onClick={showFile(x)} key={x.name}>
                    <ListItemIcon>
                      <InsertDriveFile />
                    </ListItemIcon>
                    <ListItemText primary={x.name} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
        &nbsp;
      </Paper>
    </>
  )
}
